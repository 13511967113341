/*
    The provided mixins are intended to be used with the array-like variables

    .icon-home {
      @include sprite-width($icon-home);
    }

    .icon-email {
      @include sprite($icon-email);
    }

    Example usage in HTML:

    `display: block` sprite:
    <div class="icon-home"></div>

    To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

    // CSS
    .icon {
      display: inline-block;
    }

    // HTML
    <i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
    The `sprites` mixin generates identical output to the CSS template
      but can be overridden inside of SCSS

    @include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

