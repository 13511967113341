/* 
* @include triangle within a pseudo element and add positioning properties (ie. top, left)
* $direction: up, down, left, right
*/
@mixin triangle($direction, $size: 6px, $color: #222){
  content: '';
  display: block;
  position: absolute;
  height: 0; width: 0;
  @if ($direction == 'up'){
    border-bottom: $size solid $color;
    border-left: 1/2*$size solid transparent;
    border-right: 1/2*$size solid transparent;
  }
  @else if ($direction == 'down'){
    border-top: $size solid $color;
    border-left: 1/2*$size solid transparent;
    border-right: 1/2*$size solid transparent;
  }
  @else if ($direction == 'left'){
    border-top: 1/2*$size solid transparent;
    border-bottom: 1/2*$size solid transparent;
    border-right: $size solid $color;
  }
  @else if ($direction == 'right'){
    border-top: 1/2*$size solid transparent;
    border-bottom: 1/2*$size solid transparent;
    border-left: $size solid $color;
  }
}
