
.uneditable-input {
  min-width: 10px;

}

.pull-right.plus-button {
  margin-left: 20px;
}

.row.save-button {
  margin-right: 20px;
}